<template>
  <b-container>
    <b-row>
      <b-col></b-col>
      <b-col class="pb-3" cols="12" sm="10" lg="6">
        <div>
          <img id="logo" alt="Portal logo" src="../assets/logo.svg">
          <h1 v-if="subdomain==='activate'" class="mt-3">Create your Portal</h1>
          <h1 v-if="subdomain==='trial'" class="mt-3">Create your trial Portal</h1>
          <p v-if="subdomain==='trial'">Your Portal will be deleted automatically after 24 hours.</p>
        </div>

        <div v-if="portal" class="mt-4 text-center">
          <p>We created this Portal for you:</p>
          <p>
            <PortalIdBadge :portal-id="portal.url.substring(0, 6)"/>
          </p>
          <b-button variant="primary" :href="portalLink" target="_blank">
            <p class="h4">
              <b-icon-chevron-compact-up></b-icon-chevron-compact-up>
            </p>
            <span class="text-uppercase">Connect</span>
            <br>this browser
          </b-button>
          <p class="mt-5 text-muted">
            If your browser does not redirect correctly, use this pairing code on your Portal's pairing screen:
          </p>
          <p class="text-monospace text-muted">
            {{ portal.code }}
          </p>
        </div>

        <div v-else-if="activationError">
          <b-alert show variant="danger">
            {{ activationError }}
          </b-alert>
          <b-button variant="outline-secondary" @click="reset">
            <b-icon-arrow-return-left></b-icon-arrow-return-left>
          </b-button>
        </div>

        <div v-else class="mt-4 narrow">
          <b-form @submit.stop.prevent>

            <!-- Code Input -->
            <div v-if="subdomain==='activate'">
              <b-form-group>
                <b-form-input
                    v-model="promoCode"
                    :readonly="activationInProgress"
                    :state="codeCheck.isUsable"
                    class="text-monospace text-center"
                    debounce="700"
                    placeholder="Enter Code"
                    size="lg"
                ></b-form-input>
              </b-form-group>
              <div v-if="codeCheck.spinner" class="spinner-border"></div>
              <p v-if="codeCheck"
                 :class="{'text-danger': !codeCheck.isUsable, 'text-success': codeCheck.isUsable}"
              >{{ codeCheck.message }}</p>
            </div>

            <!-- Email and Newsletter -->
            <div v-if="subdomain === 'trial' || codeCheck.isUsable">
              <b-input-group class="mt-3">
                <b-form-input
                    id="email-input"
                    v-model="ownerEmail"
                    :readonly="activationInProgress"
                    placeholder="Your email address"
                    :state="emailIsValid"
                ></b-form-input>
                <b-input-group-append>
                  <b-input-group-text id="email-info">
                    <b-link>Why?</b-link>
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
              <b-form-group class="mt-3">
                <b-form-checkbox v-model="newsletterSignup" :disabled="activationInProgress">
                  Subscribe to newsletter
                </b-form-checkbox>
              </b-form-group>

              <!-- Email Info Popup -->
              <b-popover target="email-info" placement="right" triggers="click blur">
                <h4>Your email address</h4>
                <p>We use your email address to send you the unique link to your Portal
                  and to keep you informed about its status.</p>
                <p>We only store your email address for as long as your Portal exists.</p>
                <h4>Newsletter</h4>
                <p>Tick the box to also subscribe to our newsletter. In that case, we keep your email address also after
                  this Portal's lifetime. You can unsubscribe at any time by clicking the link in the footer of our
                  emails.</p>
                <p>We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your
                  information will be transferred to Mailchimp for processing. <a href="https://mailchimp.com/legal/"
                                                                                  target="_blank">Learn more about
                    Mailchimp's privacy practices here</a>.</p>
              </b-popover>

              <!-- Activate Button -->
              <b-button variant="primary" @click="activate" :disabled="!emailIsValid" size="lg">
                <span v-if="activationInProgress"><b-spinner small></b-spinner></span>
                <span v-else><b-icon-stars></b-icon-stars> Create</span>
              </b-button>

            </div>

          </b-form>
          <p v-if="activationInProgress" class="text-muted mt-2">
            We are looking for a standby Portal and are activating it for you. Please be patient.
          </p>
        </div>

        <span id="version-string" class="text-muted small">
          {{ version }}
        </span>

      </b-col>
      <b-col></b-col>
    </b-row>
  </b-container>
</template>

<script>
import PortalIdBadge from "@/components/PortalIdBadge";
import {validate} from "email-validator";

'email-validator';

export default {
  name: 'HelloWorld',
  components: {PortalIdBadge},
  data: function () {
    return {
      ownerEmail: '',
      newsletterSignup: false,
      promoCode: '',
      codeCheck: {isUsable: null, message: '', spinner: false},
      portal: undefined,
      activationInProgress: false,
      activationError: undefined,
      responseCode: undefined,
      version: process.env.VUE_APP_VERSION || '0',
      portalVariant: 'production',
      subdomain: location.hostname.split('.')[0]
    }
  },

  methods: {
    async activate() {
      if (this.subdomain === 'activate') {
        await this.activate_real();
      } else {
        await this.activate_trial();
      }
    },

    async activate_trial() {
      this.activationInProgress = true;

      let additional_apps = '';
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('apps')) {
          additional_apps =  searchParams.get('apps');
      }

      const params = {
        owner_email: this.ownerEmail,
        newsletter_signup: this.newsletterSignup,
        env: 'prod',
        additional_apps,
      };

      try {
        const response = await this.$http.get(`https://ptlfunctionapp.azurewebsites.net/api/redeemTrialPortal`, {params: params});
        this.portal = response.data;
      } catch (error) {
        if (error.response) {
          this.activationError = error.response.data;
          this.responseCode = error.response.status;
        } else {
          this.activationError = error.message;
        }
      } finally {
        this.activationInProgress = false;
      }
    },

    async activate_real() {
      this.activationInProgress = true;

      const params = {
        promo_code: this.promoCode,
        owner_email: this.ownerEmail,
        newsletter_signup: this.newsletterSignup,
        env: 'prod',
      }

      try {
        const response = await this.$http.get(`https://ptlfunctionapp.azurewebsites.net/api/redeemCode`, {params: params})
        this.portal = response.data;
      } catch (error) {
        if (error.response) {
          this.activationError = error.response.data;
          this.responseCode = error.response.status;
        } else {
          this.activationError = error.message;
        }
      } finally {
        this.activationInProgress = false;
      }
    },

    reset: function () {
      this.promoCode = '';
      this.portal = undefined;
      this.activationInProgress = false;
      this.activationError = undefined;
      this.responseCode = undefined;
    }
  },

  computed: {
    portalLink: function () {
      return `http://${this.portal.url}/#/pair?code=${this.portal.code}`;
    },

    emailIsValid() {
      if (this.ownerEmail === '') {
        return null;
      } else {
        return validate(this.ownerEmail);
      }
    },
  },

  watch: {
    promoCode: function (value) {
      const this_ = this;
      if (value) {
        this_.codeCheck = {isUsable: null, message: '', spinner: true};
        this.$http.get(`https://ptlfunctionapp.azurewebsites.net/api/checkCode`,
            {params: {promo_code: this.promoCode}})
            .then(function (response) {
              this_.codeCheck = response.data;
            })
            .catch(function (error) {
              if (error.response) {
                this_.activationError = error.response.data;
                this_.responseCode = error.response.status;
              } else {
                this_.activationError = error.message;
              }
            })
            .finally(function () {
              this_.codeCheck.spinner = false;
            });
      } else {
        this_.codeCheck = {isUsable: null, message: '', spinner: false};
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#logo {
  width: 40%;
  margin-bottom: 4em;
}

#email-info {
  cursor: pointer;
}

form button {
  width: 10em;
}

.pairing-code-input {
  width: 6em;
  text-align: center;
  display: inline-block;
}

#version-string {
  position: fixed;
  right: 1em;
  bottom: 1em;
}

.narrow {
  padding-left: 15%;
  padding-right: 15%;
}

</style>
